









































import Vue from 'vue'
import { ApiResponse } from '@/interfaces'

export default Vue.extend({
  data() {
    return {
      busy: false,
      email: '',
      showNotification: false,
      notificationType: '',
      notificationMessage: '',
      showSuccess: false,
    }
  },
  methods: {
    submit() {
      this.busy = true
      this.showNotification = false
      this.showSuccess = false

      const errorMessage =
        "Impossible d'envoyer l'e-mail de réinitialisation du mot de passe. Veuillez vérifier les informations renseignées."

      this.$api
        .post('/password_reset_request', {
          email: this.email,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.success === true) {
            this.notificationType = 'success'
            this.notificationMessage = 'E-mail de réinitialisation de mot de passe envoyé avec succès.'
            this.showSuccess = true
          } else {
            this.notificationType = 'error'
            this.notificationMessage = errorMessage
          }
        })
        .catch(() => {
          this.notificationType = 'error'
          this.notificationMessage = errorMessage
        })
        .finally(() => {
          this.busy = false
          this.showNotification = true
        })
    },
    backToLogin() {
      this.$router.push({ name: 'login' })
    },
  },
})

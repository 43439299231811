















import Vue from 'vue'
import passwordReset from '@/components/PasswordResetForm.vue'

export default Vue.extend({
  components: {
    passwordReset,
  },
})
